<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    clipped
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, rgba(67, 67, 67, .8), rgba(67, 67, 67, .8)`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav class="mt-8">
      <v-list-item class="px-8">
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          size="165"
          contain
        >
          <v-icon v-if="!avatar" max-height="165" color="primary" size="120"
            >mdi-account</v-icon
          >
          <v-img v-if="avatar" :src="profile.avatar" max-height="165" />
        </v-list-item-avatar>
        <v-chip
          v-if="token"
          class="avatar-icon"
          light
          color="transparent"
          @click="openDialog"
          ><v-icon> mdi-camera </v-icon></v-chip
        >
        <v-dialog
          v-model="dialog_avatar"
          max-width="500px"
          @click:outside="close"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("common.changeAvatar") }}</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row dense>
                    <v-file-input
                      v-model="uploadAvatar"
                      accept=".jpg,.jpeg,.png,.svg"
                      :rules="rules"
                      :loading="loading"
                      :label="$t('common.importFile')"
                    ></v-file-input>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("common.cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="sendFile">
                {{ $t("common.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
        </v-list-item-content> -->
      </v-list-item>
      <v-list-item>
        <v-list-item-title
          class="display-1 text-center text-h3"
          v-text="profile.title"
        />
      </v-list-item>
      <!-- <v-list-item> -->
      <!-- <v-list-item-subtitle
            class="display-1 text-center text-h4"
            v-text="profile.expiredDateTitle"
          /> -->
      <!-- </v-list-item> -->
      <!-- <v-list-item>
          <v-list-item-title
            class="display-1 text-center text-h4"
            v-text="profile.expiredDateTitle+profile.expiredDate"
          />
        </v-list-item> -->
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { UserRole } from "@/definition";
// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: (vm) => ({
    items: [
      {
        title: vm.$i18n.t("common.index"),
        icon: "mdi-home",
        to: "/",
      },
      {
        title: vm.$i18n.t("urbanRenewal.manage"),
        icon: "mdi-image-filter-vintage",
        to: "/tables/urban-renewal",
      },
      // {
      //   title: vm.$i18n.t("meeting.manage"),
      //   icon: "mdi-calendar-text",
      //   to: "/tables/meeting",
      // },
      // {
      //   title: vm.$i18n.t("vote.manage"),
      //   icon: "mdi-vote",
      //   to: "/tables/issue",
      // },
      // hide temporary
      // {
      //   title: vm.$i18n.t("title.Shopping"),
      //   icon: "mdi-shopping",
      //   to: "/pages/shopping",
      // },
      // {
      //   title: vm.$i18n.t("title.UserOrder"),
      //   icon: "mdi-shopping",
      //   to: "/tables/order",
      // },
      // add import-pdf
      {
        title: vm.$i18n.t("plan.import-pdf"),
        icon: "mdi-file",
        to: "/tables/plan/import-pdf",
      },
      {
        title: vm.$i18n.t("plan.transcribe"),
        icon: "mdi-file-excel-outline",
        to: "/tables/plan/transcribe",
      },
      {
        title: vm.$i18n.t("plan.agreement"),
        icon: "mdi-handshake-outline",
        to: "/tables/plan/agreement",
      },
      {
        title: vm.$i18n.t("user.manage"),
        icon: "mdi-account-multiple",
        to: "/pages/user",
      },
      {
        title: vm.$i18n.t("user_mgr.cashflow"),
        icon: "mdi-currency-usd",
        to: "/tables/user_mgr/cashflow",
      },
    ],
    company_items: [
      {
        title: vm.$i18n.t("common.index"),
        icon: "mdi-home",
        to: "/",
      },
      {
        title: vm.$i18n.t("urbanRenewal.manage"),
        icon: "mdi-image-filter-vintage",
        to: "/tables/urban-renewal",
      },
      {
        title: vm.$i18n.t("plan.import-pdf"),
        icon: "mdi-file",
        to: "/tables/plan/import-pdf",
      },
      {
        title: vm.$i18n.t("plan.transcribe"),
        icon: "mdi-file-excel-outline",
        to: "/tables/plan/transcribe",
      },
      {
        title: vm.$i18n.t("plan.agreement"),
        icon: "mdi-handshake-outline",
        to: "/tables/plan/agreement",
      },
      {
        title: vm.$i18n.t("user.manage"),
        icon: "mdi-account-multiple",
        to: "/pages/user",
      },
      // {
      //   title: vm.$i18n.t("meeting.manage"),
      //   icon: "mdi-calendar-text",
      //   to: "/tables/meeting",
      // },
      // {
      //   title: vm.$i18n.t("vote.manage"),
      //   icon: "mdi-vote",
      //   to: "/tables/issue",
      // },
      // {
      //   title: vm.$i18n.t("title.Shopping"),
      //   icon: "mdi-shopping",
      //   to: "/pages/shopping",
      // },
      // {
      //   title: vm.$i18n.t("title.UserOrder"),
      //   icon: "mdi-shopping",
      //   to: "/tables/order",
      // },
    ],
    company_admin_items: [
      {
        title: vm.$i18n.t("common.index"),
        icon: "mdi-home",
        to: "/",
      },
      {
        title: vm.$i18n.t("urbanRenewal.manage"),
        icon: "mdi-image-filter-vintage",
        to: "/tables/urban-renewal",
      },
      {
        title: vm.$i18n.t("plan.import-pdf"),
        icon: "mdi-file",
        to: "/tables/plan/import-pdf",
      },
      {
        title: vm.$i18n.t("plan.transcribe"),
        icon: "mdi-file-excel-outline",
        to: "/tables/plan/transcribe",
      },
      {
        title: vm.$i18n.t("plan.agreement"),
        icon: "mdi-handshake-outline",
        to: "/tables/plan/agreement",
      },
      {
        title: vm.$i18n.t("user_mgr.cashflow"),
        icon: "mdi-currency-usd",
        to: "/tables/user_mgr/cashflow",
      },
      {
        title: vm.$i18n.t("user.manage"),
        icon: "mdi-account-multiple",
        to: "/pages/user",
      },
      {
        title: vm.$i18n.t("company_mgr.title"),
        icon: "mdi-account",
        to: "/tables/company_profile",
      },
    ],
    admin_items: [
      {
        title: vm.$i18n.t("user_mgr.title"),
        icon: "mdi-account",
        to: "/tables/user_mgr",
      },
      {
        title: vm.$i18n.t("company_mgr.title"),
        icon: "mdi-account",
        to: "/tables/company_mgr",
      },
      {
        title: vm.$i18n.t("urbanRenewal.manage"),
        icon: "mdi-image-filter-vintage",
        to: "/tables/urban-renewal",
      },
      {
        title: vm.$i18n.t("title.Shopping"),
        icon: "mdi-shopping",
        to: "/pages/shopping",
      },
      {
        title: vm.$i18n.t("title.Order"),
        icon: "mdi-file",
        to: "/tables/order",
      },
      {
        title: vm.$i18n.t("title.Task"),
        icon: "mdi-file-tree",
        to: "/tables/task",
      },
      {
        title: vm.$i18n.t("system_variable.title"),
        icon: "mdi-file-cog",
        to: "/tables/system",
      },
      // md5 model
      {
        title: vm.$i18n.t("md5.title"),
        icon: "mdi-file",
        to: "/tables/md5",
      },
      //upload template
      {
        title: vm.$i18n.t("plan.upload-template"),
        icon: "mdi-file",
        to: "/tables/template-management",
      },
    ],
    UserRole: UserRole,
    dialog_avatar: false,
    uploadAvatar: null,
    loading: false,
    valid: true,
    rules: [
      (value) =>
        !value ||
        value.size < 2 * 1024 * 1024 ||
        "Avatar size should be less than 2 MB!",
    ],
  }),

  computed: {
    ...mapState([
      "barColor",
      "barImage",
      "username",
      "role",
      "avatar",
      "token",
      "expiredTime",
    ]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      if (this.role === UserRole.Admin) {
        return this.admin_items.map(this.mapItem);
      } else if (this.role === UserRole.CompanyUser) {
        return this.company_items.map(this.mapItem);
      } else if (this.role === UserRole.CompanyAdmin) {
        return this.company_admin_items.map(this.mapItem);
      }
      return this.items.map(this.mapItem);
    },
    profile() {
      let expiredDate = "";
      let expiredDateTitle = "";
      let avatar = null;
      if (this.expiredTime != "") {
        expiredDateTitle = this.$i18n.t("user_mgr.expiredTime");
        expiredDate = this.TransDate(this.expiredTime).split(" ")[0];
      }
      if (this.avatar) {
        avatar = process.env.VUE_APP_SERVER_URL + this.avatar;
      }
      return {
        avatar: avatar,
        title: this.username,
        expiredDate: expiredDate,
        expiredDateTitle: expiredDateTitle,
      };
    },
  },

  methods: {
    ...mapMutations(["setAvatar"]),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    openDialog() {
      this.dialog_avatar = true;
    },
    close() {
      this.dialog_avatar = false;
      this.uploadAvatar = null;
    },
    sendFile() {
      if (!this.$refs.form.validate() || !this.uploadAvatar) {
        return;
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/user/avatar/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let formData = new FormData();
      formData.append("avatar", this.uploadAvatar);
      formData.append("suffix", this.uploadAvatar.name.split(".").pop());
      this.axios
        .post(url, formData, config)
        .then(function (response) {
          currentObj.loading = false;
          if (response.data.is_valid) {
            currentObj.setAvatar(response.data.url);
            alert("送出成功");
            currentObj.close();
          } else {
            alert(response.data.msg);
          }
        })
        .catch(function (error) {
          currentObj.loading = false;
          console.log(error);
          console.log(error.response);
          alert("送出失敗");
        });
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

.v-navigation-drawer
  background-color: rgba(0,0,0,0.1)!important

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important
    .v-list-item__avatar
      margin: 0
    .v-list-item__title
      line-height: 1.4em
    .v-chip
      &.avatar-icon
        position: absolute
        top: 130px
        left: 90px

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
